/* eslint-disable complexity */
import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMrloot, SuccessMrloot } from 'assets/images';
import { PrimaryBtn } from 'components/common/buttons/PrimaryBtn';
import { LoadSpinner } from 'components/common/loading/LoadSpinner';
import { useWindowDimensions } from 'hooks';
import { useVerifyUserMailAddress } from 'hooks/data/user/useVerifyUserMailAddress';
import { useQueryParam } from 'hooks/utility/useQueryParam';
import { useLoadTopBoxes } from 'pages/overview/hooks/useLoadTopBoxes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBoxLink } from 'utils';

import { screens } from '../../constants';

export function VerifyUserMail() {
	const userId = useQueryParam('userId');
	const code = useQueryParam('code');

	const { isLoading, mutate: verifyMailAddress, error, isSuccess } = useVerifyUserMailAddress();

	useEffect(() => {
		if (userId && code) {
			verifyMailAddress({ userId, code });
		}
	}, [userId, code, verifyMailAddress]);

	const navigate = useNavigate();
	const { screenWidth } = useWindowDimensions();

	const { data: topBoxes } = useLoadTopBoxes();

	function handleRandomBoxNavigate() {
		if (topBoxes) {
			const randomIndex = Math.floor(Math.random() * topBoxes.length);
			const selectedBox = topBoxes[randomIndex];
			const randomBoxLink = getBoxLink(selectedBox.name);
			navigate(randomBoxLink);
		} else {
			navigate('/');
		}
	}

	return (
		<div className="flex flex-col h-[calc(100dvh-130px)] justify-center items-center gap-y-[20px] pb-[40px] mx-auto">
			{isLoading && !isSuccess && !error && <LoadSpinner />}
			{!isLoading && (!!isSuccess || !!error) && (
				<>
					<img src={isSuccess ? SuccessMrloot : ErrorMrloot} alt="success" className="h-[170px] w-auto" />
					<h1 className="mb-[25px] text-center font-bold text-white text-[22px]">
						{isSuccess ? 'Your e-mail address was successfully verified' : (error?.message ?? 'Something went wrong')}
					</h1>
					<div className=" flex flex-col items-stretch w-full px-[15px] gap-y-[18px] sm:gap-y-[24px]">
						<PrimaryBtn
							style={{ height: screenWidth < screens.sm.width ? '40px' : '45px' }}
							onClick={() => navigate('/')}
							content={<span className="font-bold text-[15px] text-white">Home</span>}
						/>
						<PrimaryBtn
							style={{ height: screenWidth < screens.sm.width ? '40px' : '45px' }}
							onClick={handleRandomBoxNavigate}
							content={
								<div className="flex items-center gap-x-[5px] sm:gap-[10px]">
									<FontAwesomeIcon icon={faShuffle} className="text-white" />
									<span className="font-bold text-[15px] text-white">Random WinBoxes</span>
								</div>
							}
						/>
					</div>
				</>
			)}
		</div>
	);
}
